import Swiper from 'swiper'
import {
  Pagination,
  Keyboard,
  A11y,
  EffectFade,
  Autoplay,
} from 'swiper/modules'

export default class ModQuotes {
  constructor(el) {
    this.$el = $(el)
    this.$swiper = this.$el.find('.swiper-container')
    this.timeAutoPlay = this.$el.data('time')
  }

  init() {
    this.addSwiper()
  }

  addSwiper() {
    this.swiper = new Swiper(this.$swiper[0], {
      modules: [Pagination, Keyboard, A11y, EffectFade, Autoplay],
      speed: 500,
      loop: true,
      spaceBetween: 16,
      slidesPerView: 1,
      autoplay: {
        delay: this.timeAutoPlay,
        disableOnInteraction: false,
      },
      keyboard: {
        enabled: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      a11y: {
        enabled: true,
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
        firstSlideMessage: 'This is the first slide',
        lastSlideMessage: 'This is the last slide',
        paginationBulletMessage: 'Go to slide {{index}}',
      },
    })
  }

}

if ($('.mod-quotes').length) {
  $('.mod-quotes').each((_i, el) => {
    new ModQuotes(el).init()
  })

}
