export default class ListCategory {
  constructor(e, params = null, callBack = null, showMore = null) {
    this.$this = $(e)
    this.$btnFilter = this.$this.find('.btn-filter-item:not(.has-sub)')
    this.category = ''
    this.clsHidden = 'hidden active'
    this.callBackFunc = callBack
    this.showMore = showMore
    this.params = params
    this.$loading = $('#loadding-page')
    this.$productsHtml = $('#products-html')
  }

  init() {
    this.showTabTitle()
    this.filterFaq()
    this.controlDropdownMobile()
    if (this.showMore !== null) {
      this.showMore()
    }
  }

  controlDropdownMobile() {
    this.$this.find('.list-cat-label').click(() => {
      this.$this.toggleClass('active')
    })
    $(document).click((e) => {
      if (!$(e.target).closest(this.$this).length) {
        this.$this.removeClass('active')
      }
    })
  }

  filterFaq() {
    this.$btnFilter.on('click', (e) => {
      const $el = $(e.currentTarget)
      this.category = $el.data('category')
      this.linkCategory = $el.data('link-category')
      if ($el.hasClass('active')) {
        return
      }
      this.callBackFunc()
      this.handleChangeActive($el)
    })
  }

  handleChangeActive($el) {
    this.$btnFilter.removeClass('active')
    this.$this.removeClass('active')
    $el.addClass('active')
    this.$this.find('.text-cat').text($el.text())
  }

  showTabTitle () {
    const $active = this.$this.find('.btn-filter-item.active')
    this.$this.find('.text-cat').text($active.text())
  }
}
