export default class ModProductHeader {
  constructor(e) {
    this.$this = $(e)
    this.$productFrontsides = this.$this.find('.product-frontsides')
    this.$bgCustom = this.$this.find('.bg-custom')
    this.$patternBiryaniKits = this.$this.find('.pattern-gradient-biryani-kits')
    console.log(this.$patternBiryaniKits)
  }

  init() {
    if (this.$patternBiryaniKits.length) {
      this.matchingProductFrontSide()
      $(window).on('resize', () => {
        this.matchingProductFrontSide()
      })
    }
  }

  matchingProductFrontSide() {
    const offsetL = this.$productFrontsides.offset().left
    const style = {
      left: `${-(679 - offsetL) }px`,
    }
    if ($(window).width() < 992) {
      style.left = `${-(660 - offsetL) }px`
    }
    if ($(window).width() < 768) {
      style.left = `${-(681 - offsetL) }px`
    }
    this.$bgCustom.css('left', style.left)
  }
}
if ($('.mod-product-header').length) {
  $('.mod-product-header').each((_index, element) => {
    new ModProductHeader(element).init()
  })
}
