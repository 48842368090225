import Swiper from 'swiper'
import {
  Pagination,
  Keyboard,
  A11y,
} from 'swiper/modules'

export default class ModRecipe {
  constructor(el) {
    this.$el = $(el)
    this.$swiper = this.$el.find('.swiper-container')
    this.swiper = null
  }

  init() {
    this.addSwiper()
    $(window).on('resize', () => {
      this.addSwiper()
    })
  }

  addSwiper() {
    if (window.innerWidth >= 1200) {
      if (this.swiper) {
        this.swiper.destroy(true, true)
        this.swiper = null
      }
    } else {
      if (this.swiper) {
        return
      }
      const isRewid = this.$swiper.find('.swiper-slide').length === 3
      this.swiper = new Swiper(this.$swiper[0], {
        modules: [Pagination, Keyboard, A11y],
        slidesPerView: 1,
        speed: 500,
        loop: true,
        spaceBetween: 16,
        keyboard: {
          enabled: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        a11y: {
          enabled: true,
          prevSlideMessage: 'Previous slide',
          nextSlideMessage: 'Next slide',
          firstSlideMessage: 'This is the first slide',
          lastSlideMessage: 'This is the last slide',
          paginationBulletMessage: 'Go to slide {{index}}',
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            loop: !isRewid,
            rewind: isRewid,
          },
        },
        on: {
          transitionEnd: () => {},
        },
      })
    }

  }

}

if ($('.mod-recipe').length) {
  $('.mod-recipe').each((_i, el) => {
    new ModRecipe(el).init()
  })

}
