import ListCategory from '../components/ListCategory'

export default class ModProductsCategory {
  constructor(e) {
    this.$this = $(e)
    this.faqItem = this.$this.find('.faq-item')
    this.category = ''
    this.clsHidden = 'hidden active'
    this.listCat = null
    this.linkCategory = ''
    this.page = ''
    this.$loading = $('#loadding-page')
    this.$productsHtml = $('#products-html')
  }

  init() {
    this.listCat = new ListCategory(this.$this.find('.list-cat-wrapper'), 'category', this.callAfterClick.bind(this), this.showMore.bind(this))
    this.listCat.init()
  }

  callAfterClick () {
    this.category = this.listCat.category
    this.page = this.listCat.page
    this.linkCategory = this.listCat.linkCategory
    this.sendAjax({
      category: this.category,
      page: this.page,
    })
  }

  showMore () {
    this.$this.on('click', '.btn-showmore', (e) => {
      e.preventDefault()
      const $this = $(e.currentTarget)
      const page = $this.data('page')
      const category = $this.data('category')
      this.linkCategory = $this.attr('href')
      this.sendAjax({
        category,
        page,
        notScroll: true,
      })
    })
  }

  sendAjax ({ category, page = 1, $el = null, notScroll = false}) {
    const $this = this
    this.$loading.removeClass('hidden')
    $.ajax( {
      type: 'post',
      dataType: 'text',
      url: window.AJAX_URL,
      data: {
        action: window.ajaxAction || 'get_products',
        form: {
          category,
          page,
        },
      },
      success(response) {
        const resp = JSON.parse(response)
        $this.$productsHtml.html(resp.html)
        $('#banner-internal').html(resp.banner)
        $('#banner-internal-heading').html(resp.heading)
        if ($el) {
          $this.listCat.handleChangeActive($el)
        }
        $this.setParams()
        $this.$loading.addClass('hidden')
        if ($this.$this.find('.faq-item:not(.hidden)').length === 0) {
          $this.$this.find('.no-result').removeClass('hidden')
        } else {
          $this.$this.find('.no-result').addClass('hidden')
        }
      },
      error(jqXHR) {
        console.log(`The following error occured: ${ jqXHR}`)
      },
    })
  }

  setParams() {
    window.history.pushState({}, '', this.linkCategory)
  }
}
if ($('.mod-products-category').length) {
  $('.mod-products-category').each((_index, element) => {
    new ModProductsCategory(element).init()
  })
}
