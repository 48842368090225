import ListCategory from '../components/ListCategory'

export default class ModFaqs {
  constructor(e) {
    this.$this = $(e)
    this.$btnFilter = this.$this.find('.btn-filter-item')
    this.faqItem = this.$this.find('.faq-item')
    this.category = ''
    this.clsHidden = 'hidden active'
    this.listCat = null
  }

  init() {
    this.listCat = new ListCategory(this.$this.find('.list-cat-wrapper'), 'faq_cat', this.callAfterClick.bind(this))
    this.listCat.init()
  }

  callAfterClick () {
    this.category = this.listCat.category
    if (this.category) {
      this.faqItem.each((_index, element) => {
        const $faqItem = $(element)
        const listCat = $faqItem.data('category').split(', ')
        if (listCat.includes(this.category)) {
          $faqItem.removeClass(this.clsHidden)
        } else {
          $faqItem.addClass(this.clsHidden)
        }
      })
    } else {
      this.faqItem.removeClass(this.clsHidden)
    }
    if (this.$this.find('.faq-item:not(.hidden)').length === 0) {
      this.$this.find('.no-result').removeClass('hidden')
    } else {
      this.$this.find('.no-result').addClass('hidden')
    }
  }
}
if ($('.mod-faqs').length) {
  $('.mod-faqs').each((_index, element) => {
    new ModFaqs(element).init()
  })
}
