export default class ModAccordion {
  constructor(e) {
    this.$this = $(e)
    this.jsClassExpand = 'js-expand'
    this.openOneClass = '.only-open-one'
    this.ariaExpanded = 'aria-expanded'
    this.animatingClass = 'animating'
    this.classParent = 'is-open'
  }

  handleToggle(elem) {
    const $content = this.findContent($(elem))
    const open = $(elem).attr(this.ariaExpanded).trim() === 'true'
    const id = $(elem).attr('aria-controls')
    const isOnlyOpenOne = this.$this.is(this.openOneClass)
    const animationDuration = 400
    const isOpenLeft = $(elem).hasClass('feature-accordion-item')
    if (isOpenLeft && open) return
    if (isOnlyOpenOne) {
      if (!open) {
        setTimeout(() => {
          this.deActivate(isOpenLeft, $content)
          if (!isOpenLeft) {
            $content.slideDown()
          } else {
            $content.removeClass('hidden')
          }
          $(elem).parents('.js-accordion-item').addClass(this.classParent)
          this.$this.find(`[aria-controls="${id}"]`).attr(this.ariaExpanded, 'true').attr('tabindex', -1).addClass(this.jsClassExpand)
          $content.addClass(this.jsClassExpand)
          setTimeout(() => {
            $content.find('.tab-wrap').addClass('active')
            $content.find('.tab-img').addClass('active')
          }, 400)
        }, 0)

      } else {
        this.deActivate(isOpenLeft, $content)
      }

    } else {
      if ($(elem).hasClass(this.animatingClass) || isOpenLeft) {
        return
      }
      if (!open) {
        $content.slideDown(animationDuration)
      } else {
        $content.slideUp(animationDuration).removeClass('hidden')
      }
      $(elem).addClass(this.animatingClass).attr(this.ariaExpanded, `${!open}`).toggleClass(this.jsClassExpand)
      setTimeout(() => {
        $(elem).removeClass(this.animatingClass)
      }, animationDuration + 100)
    }
  }

  deActivate(isOpenLeft = false, $contentActive = null) {
    const $buttons = this.$this.find('button[aria-expanded]')
    $buttons.each((_i, elem) => {
      const $elem = $(elem)
      const $contentEl = this.findContent($elem)
      if (($contentEl.is($contentActive) || $elem.attr(this.ariaExpanded) === 'false') && isOpenLeft) {
        return
      }
      $(elem).parents('.js-accordion-item').removeClass(this.classParent)
      $elem.attr({
        'aria-expanded': `false`,
        'tabindex': '0',
      }).removeClass(this.jsClassExpand)
      $contentEl.removeClass(this.jsClassExpand)
      if (!isOpenLeft) {
        $contentEl.slideUp()
      } else {
        $contentEl.find('.tab-wrap').removeClass('active')
        $contentEl.find('.tab-img').removeClass('active')
        setTimeout(() => {
          $contentEl.addClass('hidden')
        }, 500)
      }

    })
  }

  findContent(btn) {
    const controlsId = btn.attr('aria-controls')
    return $(`#${controlsId}`)
  }

  addListeners() {
    this.$this.find('button[aria-expanded]').each((_i, elem) => {
      $(elem).on('click', (e) => {
        if ($(e.currentTarget).is('.mouse')) {
          this.handleToggle(elem)
        }
      })

      $(elem).on('keydown', (e) => {
        this.keydownEventListener(e, elem)
      })
    })
  }

  keydownEventListener(event, element) {
    const key = event.keyCode

    switch (key) {
      case 32:
        event.preventDefault()
        this.handleToggle(element)
        break

      case 13:
        event.preventDefault()
        this.handleToggle(element)
        break

      default:
        break
    }
  }

  init() {
    this.addListeners()
  }
}

$('.mod-accordion').each((_i, el) => {

  new ModAccordion(el).init()
})