export default class ProductDetail {
  constructor() {
    this.$el = $('.detail-products')
    this.$btnTabs = this.$el.find('.weight-tab')
    this.swiper = null
  }

  init() {
    this.controlTab()
  }

  controlTab() {
    this.$btnTabs.on('click', (e) => {
      const $el = $(e.currentTarget)
      const dataWeight = $el.data('weightopt')
      this.$btnTabs.removeClass('active')
      $el.addClass('active')
      this.$el.find(`[data-weight]`).addClass('hidden')
      console.log(this.$el.find(`[data-weight]`))
      this.$el.find(`[data-weight="${dataWeight}"]`).removeClass('hidden')
    })
  }
}
new ProductDetail().init()