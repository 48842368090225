export default class ModSearch {
  constructor(e) {
    this.$this = $(e)
    this.$searchToggleBtn = this.$this.find('.search-toggle button')
    this.classLoadMore = '.btn-showmore, .js-showmore-recipes, .js-showmore-pages'
    this.activeBtnClass = 'active'
    this.hideClass = 'hidden'
    this.$loading = $('#loadding-page')
    this.$html = $('#filter-section')
    this.$nextPage = this.$this.find('#next-page')
    this.$search = this.$this.find('#search')
  }

  init() {
    this.showAllRecipes()
    this.onFilterEvent()
    this.onLoadMore()
    // Check query has tab
    const urlParams = new URLSearchParams(window.location.search)
    const tab = urlParams.get('tab')
    if (tab) {
      setTimeout(() => {
        this.scrollToElm()
      }, 200)
    }
  }

  onFilterEvent() {
    this.$searchToggleBtn.on('click', (e) => {
      const $current = $(e.currentTarget)
      const category = $current.data('category')
      this.sendAjax({
        type: category,
        cb: () => {
          this.$searchToggleBtn.removeClass(this.activeBtnClass)
          $current.addClass(this.activeBtnClass)
          this.scrollToElm()
        },
      })
    })
  }

  scrollToElm() {
    $('body, html').animate({
      scrollTop: this.$this.offset().top - $('.header').height(),
    }, 500)
  }

  showAllRecipes () {
    const $recipeCard = this.$this.find('.recipe-card')
    $recipeCard.removeClass(this.hideClass)
    if (this.$this.find('[data-category="recipes"]').data('all') > $recipeCard.length) {
      this.$this.find('.js-showmore-recipes').removeClass(this.hideClass)
    }
  }

  onLoadMore () {
    $(document).on('click', this.classLoadMore, (e) => {
      e.preventDefault()
      const $this = $(e.currentTarget)
      const params = {
        page: this.$nextPage.val(),
      }
      if ($this.hasClass('js-showmore-recipes')) {
        params.type = 'recipes'
      } else if ($this.hasClass('js-showmore-pages')) {
        params.type = 'pages'
      } else {
        params.type = 'products'
      }
      this.sendAjax(params)
    })
  }

  sendAjax({ type, page = 1, cb = null }) {
    const $this = this
    this.$loading.removeClass(this.hideClass)
    $.ajax( {
      type: 'post',
      dataType: 'text',
      url: window.AJAX_URL,
      data: {
        action: window.ajaxAction || 'get_search',
        form: {
          type,
          page,
          search: this.$search.val(),
        },
      },
      success(response) {
        $this.$html.html(response)
        $this.$loading.addClass($this.hideClass)
        if (typeof cb === 'function') {
          cb()
        }
        const params = new URLSearchParams()
        params.set('s', $this.$search.val())
        params.set('tab', type)
        if (parseInt(page, 10) !== 1) {
          params.set('page', page)
        }
        window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`)

        setTimeout(() => {
          $this.showAllRecipes()
          $this.$nextPage.val(parseInt(page, 10) + 1)
        }, 100)
      },
      error(jqXHR) {
        console.log(`The following error occured: ${ jqXHR}`)
      },
    })
  }
}

if ($('.mod-search').length) {
  $('.mod-search').each((_index, element) => {
    new ModSearch(element).init()
  })
}
