export default class ModFilterRecipes {
  constructor(e) {
    this.$this = $(e)
    this.$btnFilter = this.$this.find('.btn-filter-recipes')
    this.$filterRecipes = this.$this.find('.filter-recipes')
    this.$closeFilter = this.$this.find('.js-close-filter-recipes')
    this.$clearFilter = this.$this.find('.js-clear-filter')
    this.$applyFilter = this.$this.find('.js-apply-filter')
    this.$dropdown = this.$this.find('.select-c8')
    this.$formFilter = this.$this.find('.form-filter')
    this.$items = this.$this.find('.recipe-card')
    this.$showmoreBtn = this.$this.find('.js-showmore-recipes')
    this.$loading = this.$this.find('.filter-loading')
    this.cuisine = 'all'
    this.dish = 'all'
    this.dietary = 'all'
    this.product = 'all'
    this.time = 'all'
    this.clearing = false
    this.dataDropdown = {}
    this.searchParams = null
    this.$paged = this.$this.find('[name="paging"]')
    this.postPerPage = 9
  }

  init() {
    this.controlFilterBar()
    this.filterFunction()
  }

  controlFilterBar() {
    this.$btnFilter.click(() => {
      this.openFilter()
    })
    this.$closeFilter.click(() => {
      this.closeFilter()
    })
    this.$clearFilter.click(() => {
      this.$paged.val(1)
      this.clearing = true
      this.closeFilter()
      this.$this.find('.dropdown-menu-c8').each((i, e) => {
        $(e).find('li').eq(0).find('a').trigger('click')
      })
      this.clearing = false
      this.filterFunction()
    })
    this.$applyFilter.click(() => {
      this.closeFilter()
    })

    this.$dropdown.on('change', () => {
      if (!this.clearing) {
        this.$paged.val(1)
        this.filterFunction()
      }
    })
    this.$showmoreBtn.click(() => {
      const paged = this.$paged.val()
      this.$paged.val(parseInt(paged, 10) + 1)
      this.filterFunction()
    })
  }

  getParamsData() {
    this.searchParams = new URLSearchParams(window.location.search)

    return [
      {
        dataName: 'cuisine-type',
        value: this.searchParams.get('cuisine-type') || 'all',
      },
      {
        dataName: 'dish-type',
        value: this.searchParams.get('dish-type') || 'all',
      },
      {
        dataName: 'dietary-restriction',
        value: this.searchParams.get('dietary-restriction') || 'all',
      },
      {
        dataName: 'product',
        value: this.searchParams.get('product') || 'all',
      },
      {
        dataName: 'time',
        value: this.searchParams.get('time') || 'all',
      },
    ]
  }

  filterFunction() {
    this.$loading.removeClass('hidden')
    setTimeout(() => {
      this.$loading.addClass('hidden')
      this.beforeFilter()

      // Get parameters data once and reuse it
      const arrayData = this.getParamsData()

      // Use ternary operator to simplify if-else statement
      const countNotAll = arrayData.filter((item) => item.value !== 'all').length
      this.$this.find('.filter-count').text(countNotAll !== 0 ? `(${countNotAll})` : '')

      // Use Math.max to ensure page is at least 1
      const page = Math.max(this.searchParams.get('paging') || 1, 1)

      this.dataDropdown = []
      let countPage = 0
      this.$showmoreBtn.hide()

      this.$items.each((_index, element) => {
        const $element = $(element)
        const dataMap = {
          'cuisine-type': String($element.data('cuisine')),
          'dish-type': String($element.data('dish')),
          'dietary-restriction': String($element.data('dietary')).split(', '),
          'product': String($element.data('product')),
          'time': String($element.data('time')),
        }
        const show = arrayData.every((e) => {
          const decodedValue = decodeURIComponent(e.value)
          return decodedValue === 'all' || (e.dataName === 'dietary-restriction' ? dataMap[e.dataName].includes(decodedValue) : dataMap[e.dataName] === decodedValue)
        })

        if (show) {
          countPage += 1
          // Use ternary operator to simplify if-else statement
          $element.toggle(countPage <= page * this.postPerPage)
          if (countPage > page * this.postPerPage) this.$showmoreBtn.show()
        } else {
          $element.hide()
        }

        this.dataDropdown.push(dataMap)
      })

      const params = new URLSearchParams(window.location.search).toString()
      this.$clearFilter.attr('disabled', params.split('&').reduce((acc, item) => {
        if (!['', 'paging'].includes(item.split('=')[0])) {
          acc++
        }
        return acc
      }, 0) === 0)

      this.renderFilter()
    }, 400)

  }

  getFilteredData(exclude = []) {
    const searchParams = new URLSearchParams(window.location.search)
    const cuisine = exclude.includes('cuisine-type') ? null : searchParams.get('cuisine-type')
    const dish = exclude.includes('dish-type') ? null : searchParams.get('dish-type')
    const dietary = exclude.includes('dietary-restriction') ? null : searchParams.get('dietary-restriction')
    const product = exclude.includes('product') ? null : searchParams.get('product')
    const time = exclude.includes('time') ? null : searchParams.get('time')

    return this.dataDropdown.map((element) => {
      const hidden = [
        cuisine && cuisine.split(',').includes(element['cuisine-type']) === false,
        dish && dish.split(',').includes(element['dish-type']) === false,
        dietary && element['dietary-restriction'].some(restriction => dietary.split(',').includes(restriction)) === false,
        product && product.split(',').includes(element.product.toString()) === false,
        time && time.split(',').includes(element.time.toString()) === false,
      ].some((value) => value)
      // console.log(element['dietary-restriction'], dietary)
      return {
        ...element,
        hidden,
      }
    })
  }

  getUniqueOptions(filterType) {
    const data = this.getFilteredData([filterType]).map((element) => element[filterType])
    const uniqueData = [...new Set(data)]

    return uniqueData.map((value) => ({
      value,
      disabled: !this.getFilteredData([filterType]).some((element) => element[filterType] === value && element.hidden === false),
    }))
  }

  renderFilter() {

    const filterData = [
      {
        name: 'cuisine-type',
        option: this.getUniqueOptions('cuisine-type'),
      },
      {
        name: 'dish-type',
        option: this.getUniqueOptions('dish-type'),
      },
      {
        name: 'dietary-restriction',
        option: this.getUniqueOptions('dietary-restriction'),
      },
      {
        name: 'product',
        option: this.getUniqueOptions('product'),
      },
      {
        name: 'time',
        option: this.getUniqueOptions('time'),
      },
    ]
    this.filterDropdown(filterData)
  }

  filterDropdown(dataDropdown = null) {
    dataDropdown.forEach((e) => {
      const values = e.option
      const $dropdown = this.$this.find(`[name="${e.name}"]`)
      const $dropdownMenu = $dropdown.parent()
      $dropdownMenu.addClass('disabled')
      if (e.name === 'dietary-restriction') {
        const enabledValues = values.filter((value) => !value.disabled)
        let itemEnabled = enabledValues.map((value) => value.value).flat()
        itemEnabled = [...new Set(itemEnabled)] // Remove duplicates
        $dropdownMenu.find('li').addClass('hidden')
        if (itemEnabled.length) {
          $dropdownMenu.removeClass('disabled')
          itemEnabled.forEach((value) => {
            const idx = $dropdown.find(`option[value="${value}"]`).index()
            $dropdownMenu.find('li').eq(idx).removeClass('hidden')
          })
        }
      } else {
        values.forEach((value) => {
          const idx = $dropdown.find(`option[value="${value.value}"]`).index()
          if (idx !== -1) {
            if (!value.disabled) {
              $dropdownMenu.find('li').eq(idx).removeClass('hidden')
              $dropdownMenu.removeClass('disabled')
            } else {
              $dropdownMenu.find('li').eq(idx).addClass('hidden')
            }
          }
        })
      }
    })
  }

  beforeFilter() {
    const params = new URLSearchParams()
    const data = this.$formFilter.serialize()
    const arrayData = data.split('&')
    arrayData.forEach((e) => {
      const [key, value] = e.split('=')
      const decodedValue = decodeURIComponent(value)
      if (decodedValue !== 'all' && !(key === 'paging' && decodedValue === '1')) {
        params.append(key, decodedValue)
      }
    })

    // Get all params
    const stringParams = params.toString()
    if (stringParams) {
      window.history.pushState({}, '', `?${stringParams}`)
    } else {
      window.history.pushState({}, '', window.location.pathname)
    }
  }

  dropdownSelected() {
    let selected = 0
    let selectedDropdown = null

    this.$dropdown.each((_index, element) => {
      if ($(element).val() !== 'all') {
        selected += 1
        selectedDropdown = $(element)
      }
    })

    return selected === 1 ? selectedDropdown : false
  }

  openFilter() {
    this.$filterRecipes.addClass('active')
  }

  closeFilter() {
    this.$filterRecipes.removeClass('active')
  }
}
if ($('.mod-filter-recipes').length) {
  $('.mod-filter-recipes').each((_index, element) => {
    new ModFilterRecipes(element).init()
  })
}
