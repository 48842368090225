export default class GravityForm {
  init() {
    this.confirmation()
    this.loaded = $('body.home').length
  }

  confirmation() {
    // Show alert when form is submitted
    $(document).bind('gform_post_render', (event, formId) => {
      if (!this.loaded) {
        this.loaded = true
      } else {
        this.scrollToEle($(`#gform_wrapper_${formId}`))
      }
    })
    $(document).bind('gform_confirmation_loaded', (event, formId) => {
      if (!window.location.href.includes('#thank-you')) {
        const url = `${window.location.href }#thank-you`
        window.history.replaceState(null, null, url)
      }
      this.scrollToEle($(`#gform_confirmation_wrapper_${formId}`))
    })
  }

  scrollToEle($ele) {
    $('html, body').animate({
      scrollTop: $ele.offset().top - $('#header').innerHeight() - 150,
    })
  }
}

new GravityForm().init()
