import Swiper from 'swiper'
import {
  Pagination,
  Keyboard,
  A11y,
  EffectFade,
} from 'swiper/modules'

export default class ModOurHistory {
  constructor(el) {
    this.$el = $(el)
    this.$swiper = this.$el.find('.swiper-container')
  }

  init() {
    this.addSwiper()
  }

  addSwiper() {
    const isRewidInTablet = this.$swiper.find('.swiper-slide').length === 3
    const isRewidInDesktop = this.$swiper.find('.swiper-slide').length < 6
    const isRewidInDesktopLarge = this.$swiper.find('.swiper-slide').length < 8
    this.swiper = new Swiper(this.$swiper[0], {
      modules: [Pagination, Keyboard, A11y, EffectFade],
      speed: 500,
      loop: true,
      spaceBetween: 16,
      slidesPerView: 1,
      keyboard: {
        enabled: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 16,
          rewind: isRewidInTablet,
          loop: !isRewidInTablet,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
          rewind: isRewidInDesktop,
          loop: !isRewidInDesktop,
        },
        1920: {
          slidesPerView: 4,
          spaceBetween: 20,
          rewind: isRewidInDesktopLarge,
          loop: !isRewidInDesktopLarge,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      a11y: {
        enabled: true,
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
        firstSlideMessage: 'This is the first slide',
        lastSlideMessage: 'This is the last slide',
        paginationBulletMessage: 'Go to slide {{index}}',
      },
    })
  }

}

if ($('.mod-our-history').length) {
  $('.mod-our-history').each((_i, el) => {
    new ModOurHistory(el).init()
  })

}
