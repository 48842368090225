import Swiper from 'swiper'
import {
  Pagination,
  Keyboard,
  A11y,
  EffectFade,
  Autoplay,
} from 'swiper/modules'

export default class ModThreeColumn {
  constructor(el) {

    this.$el = $(el)
    this.$swiper = this.$el.find('.swiper-container')

  }

  init() {
    $(window).on('load resize', () => {
      this.addSwiper()
    })
  }

  addSwiper() {
    if (window.innerWidth > 1200) {
      if (this.swiper) {
        this.swiper.destroy(true, true)
        this.swiper = null
      }
    } else {
      if (this.swiper) {
        return
      }
      const isRewid = this.$swiper.find('.swiper-slide').length === 3
      this.swiper = new Swiper(this.$swiper[0], {
        modules: [Pagination, Keyboard, A11y, EffectFade, Autoplay],
        speed: 500,
        loop: true,
        spaceBetween: 32,
        slidesPerView: 1,
        keyboard: {
          enabled: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 16,
            loop: !isRewid,
            rewind: isRewid,
          },
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
        a11y: {
          enabled: true,
          prevSlideMessage: 'Previous slide',
          nextSlideMessage: 'Next slide',
          firstSlideMessage: 'This is the first slide',
          lastSlideMessage: 'This is the last slide',
          paginationBulletMessage: 'Go to slide {{index}}',
        },
      })
    }
  }

}

if ($('.mod-three-column').length) {
  $('.mod-three-column').each((_i, el) => {
    new ModThreeColumn(el).init()
  })

}
